


























import BaseRadioButton from '@labor-digital/vuetiful/src/Components/RadioButton/RadioButton.vue';
import Icon from '../../Misc/Icon/Icon.vue';

export default {
    name: 'RadioButton',
    components: {Icon, BaseRadioButton},
    props: ['value'],
    computed: {
        emittedValue: {
            get()
            {
                return this.value;
            },
            set(val)
            {
                this.$emit('input', val);
            }
        }
    }
};
