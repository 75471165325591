var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-radio-button",
    _vm._b(
      {
        scopedSlots: _vm._u([
          {
            key: "customRadioIcon",
            fn: function () {
              return [_c("span", { staticClass: "radioButton__radio" })]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.emittedValue,
          callback: function ($$v) {
            _vm.emittedValue = $$v
          },
          expression: "emittedValue",
        },
      },
      "base-radio-button",
      Object.assign({}, _vm.$props, _vm.$attrs),
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }