
























import BaseTextArea from '@labor-digital/vuetiful/src/Components/BaseTextArea/BaseTextArea';

export default {
    name: 'TextareaField',
    components: {BaseTextArea}
};
