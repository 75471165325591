






























































































































import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import Spinner from 'vue-infinite-loading/src/components/Spinner.vue';
import {FbPixelService} from '../../../Service/FbPixelService';
import {MailService} from '../../../Service/MailService';
import {MenuService} from '../../../Service/MenuService';
import Checkbox from '../../Form/Checkbox/Checkbox';
import InputField from '../../Form/InputField/InputField';
import RadioButton from '../../Form/RadioButton/RadioButton';
import TextareaField from '../../Form/Textarea/TextareaField';
import Btn from '../../Misc/Btn/Btn.vue';
import LinkWrap from '../../Misc/Link/LinkWrap/LinkWrap';
import InfinityLoadingSpinner from '../../Misc/Spinner/InfinityLoadingSpinner.vue';

export default {
    name: 'Contact',
    components: {InfinityLoadingSpinner, LinkWrap, Checkbox, RadioButton, InputField, TextareaField, Btn, Spinner},
    props: {
        context: null as PageContext,
        mailType: {
            type: String,
            default: 'contact'
        },
        successType: {
            type: String,
            default: 'default'
        },
        customer: {
            type: String,
            default: null
        },
        email: {
            type: String,
            required: true
        },
        token: {
            type: String,
            required: true
        },
        additionalPayload: {
            type: Object,
            default: () => {return {};}
        }
    },
    data()
    {
        return {
            isSent: false,
            isSending: false,
            department: '',
            data: {
                name: {
                    first: '',
                    last: ''
                },
                email: '',
                company: '',
                phone: '',
                message: '',
                confirmGdpr: false,
                pot: ''
            },
            confirmGdprOptions: [
                {
                    label: 'privacy policy',
                    value: 'gdpr',
                    checked: false,
                    confirmGdpr: true
                }
            ]
        };
    },
    methods: {
        validate(): boolean
        {
            return isEmpty(this.data.pot) && this.isEmailValid && (!this.isForCompanies || this.isCompanyValid) && this.isConfirmGdprValid &&
                   (isEmpty(this.customer) || this.isDepartmentValid);
        },
        onButtonClick()
        {
            if (this.isFormValid) {
                this.isSending = true;

                let data = {
                    ...this.additionalPayload,
                    ...this.data,
                    context: this.mailType
                };

                if (isEmpty(this.customer)) {
                    data = {
                        ...data,
                        origin: this.isForCompanies ? 'companies' : 'applicants'
                    };
                } else {
                    data = {
                        ...data,
                        department: this.department,
                        customer: this.customer
                    };
                }

                MailService.send(this.email, this.token, data).then(() => {
                    FbPixelService.track(this.mailType);
                    this.isSending = false;
                    this.isSent = true;
                }).catch(() => {
                    this.isSending = false;
                });
            }
        }
    },
    computed: {
        isForCompanies(): boolean
        {
            return MenuService.isForCompanies();
        },
        isFormValid(): boolean
        {
            return this.validate();
        },
        isEmailValid(): boolean
        {
            return !isEmpty(this.data.email) && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(this.data.email);
        },
        emailErrorMessage(): string | null
        {
            return this.isEmailValid || this.data.email === '' ? null : this.$t('requestEmployees.form.email.error');
        },
        isDepartmentValid(): boolean
        {
            return !isEmpty(this.department);
        },
        departmentErrorMessage(): string | null
        {
            if (!this.isDepartmentValid) {
                return null;
            }

            return this.isDepartmentValid || this.data.department === '' ? null : this.$t('requestEmployees.form.general.error');
        },
        isCompanyValid(): boolean
        {
            return !isEmpty(this.data.company);
        },
        companyErrorMessage(): string | null
        {
            if (!this.isForCompanies) {
                return null;
            }

            return this.isCompanyValid || this.data.company === '' ? null : this.$t('requestEmployees.form.company.error');
        },
        isConfirmGdprValid(): boolean
        {
            return this.data.confirmGdpr === true;
        },
        confirmGdprErrorMessage(): string | null
        {
            return this.isConfirmGdprValid || this.data.confirmGdpr === false ? null : this.$t('requestEmployees.form.confirmGdpr.error');
        },
        // @todo make this more intuitive <- Single checkbox component
        gdprModelBridge: {
            set(v)
            {
                this.data.confirmGdpr = v.length > 0;
            }, get()
            {
                return [];
            }
        },
        privacyPolicyPage()
        {
            const pageContext = this.context.pageContext ?? this.context;
            return pageContext.linkRepository.get('privacyPolicy');
        }
    }
};
