var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact" }, [
    !_vm.isSent
      ? _c(
          "div",
          {
            staticClass: "contact__inner",
            class: { "contact__inner--width": this.customer },
            style: { opacity: _vm.isSending ? 0.5 : null },
          },
          [
            _c(
              "div",
              { staticClass: "contact__column contact__column--small" },
              [
                _c("label", { attrs: { for: "contact-name-first" } }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("contact.form.name.first.label")) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("input-field", {
                  attrs: {
                    id: "contact-name-first",
                    type: "text",
                    placeholder: _vm.$t("contact.form.name.first.placeholder"),
                  },
                  model: {
                    value: _vm.data.name.first,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.name, "first", $$v)
                    },
                    expression: "data.name.first",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "contact__column contact__column--small" },
              [
                _c("label", { attrs: { for: "contact-name-first" } }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("contact.form.name.last.label")) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("input-field", {
                  attrs: {
                    id: "contact-name-last",
                    type: "text",
                    placeholder: _vm.$t("contact.form.name.last.placeholder"),
                  },
                  model: {
                    value: _vm.data.name.last,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.name, "last", $$v)
                    },
                    expression: "data.name.last",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "contact__column contact__column--small" },
              [
                _c("label", { attrs: { for: "contact-email" } }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("contact.form.email.label")) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("input-field", {
                  attrs: {
                    id: "contact-email",
                    type: "email",
                    required: "required",
                    error: _vm.emailErrorMessage,
                    placeholder: _vm.$t("contact.form.email.placeholder"),
                  },
                  model: {
                    value: _vm.data.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "email", $$v)
                    },
                    expression: "data.email",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "contact__column contact__column--small" },
              [
                _c("label", { attrs: { for: "contact-phone" } }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("contact.form.phone.label")) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("input-field", {
                  attrs: {
                    id: "contact-phone",
                    type: "text",
                    placeholder: _vm.$t("contact.form.phone.placeholder"),
                  },
                  model: {
                    value: _vm.data.phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "phone", $$v)
                    },
                    expression: "data.phone",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isForCompanies
              ? _c(
                  "div",
                  { staticClass: "contact__column contact__column--small" },
                  [
                    _c("label", { attrs: { for: "contact-company" } }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("contact.form.company.label")) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.isForCompanies
                      ? _c("input-field", {
                          attrs: {
                            id: "contact-company",
                            type: "text",
                            error: _vm.companyErrorMessage,
                            placeholder: _vm.$t(
                              "contact.form.company.placeholder"
                            ),
                          },
                          model: {
                            value: _vm.data.company,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "company", $$v)
                            },
                            expression: "data.company",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.customer
              ? _c(
                  "div",
                  { staticClass: "contact__column contact__column--small" },
                  [
                    _c("label", { attrs: { for: "contact-department" } }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("contact.form.company.department")) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("input-field", {
                      attrs: {
                        id: "contact-department",
                        type: "text",
                        required: "required",
                        error: _vm.departmentErrorMessage,
                        placeholder: _vm.$t("contact.form.company.department"),
                      },
                      model: {
                        value: _vm.department,
                        callback: function ($$v) {
                          _vm.department = $$v
                        },
                        expression: "department",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "contact__column contact__column--pot" },
              [
                _c("input-field", {
                  attrs: { id: "contact-pot", type: "text" },
                  model: {
                    value: _vm.data.pot,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "pot", $$v)
                    },
                    expression: "data.pot",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "contact__column" },
              [
                _c("label", { attrs: { for: "contact-message" } }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("contact.form.message.label")) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("textarea-field", {
                  attrs: {
                    id: "contact-message",
                    placeholder: _vm.$t("contact.form.message.placeholder"),
                  },
                  model: {
                    value: _vm.data.message,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "message", $$v)
                    },
                    expression: "data.message",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "contact__column" },
              [
                _c("checkbox", {
                  attrs: {
                    items: _vm.confirmGdprOptions,
                    error: _vm.confirmGdprErrorMessage,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function (props) {
                          return [
                            props.item.confirmGdpr
                              ? _c(
                                  "span",
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "contact.form.confirmGdpr.options.yes.1"
                                          )
                                        ) +
                                        "\n                    "
                                    ),
                                    _c(
                                      "link-wrap",
                                      {
                                        attrs: { link: _vm.privacyPolicyPage },
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "contact.form.confirmGdpr.options.yes.2"
                                              )
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "contact.form.confirmGdpr.options.yes.3"
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1168404071
                  ),
                  model: {
                    value: _vm.gdprModelBridge,
                    callback: function ($$v) {
                      _vm.gdprModelBridge = $$v
                    },
                    expression: "gdprModelBridge",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "contact__column" },
              [
                _c(
                  "btn",
                  {
                    attrs: { "is-disabled": !_vm.isFormValid },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onButtonClick.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("contact.form.submit")) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        )
      : _c("div", { staticClass: "contact__column contact__column--success" }, [
          _vm.successType === "default"
            ? _c("p", [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("contact.success")) +
                    "\n        "
                ),
              ])
            : _c("p", [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("contact.successApplication")) +
                    "\n        "
                ),
              ]),
        ]),
    _vm._v(" "),
    _vm.isSending
      ? _c(
          "div",
          { staticClass: "contact__overlay" },
          [_c("infinity-loading-spinner", { staticClass: "contact__spinner" })],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }